(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){

},{}],2:[function(require,module,exports){
/*!
  * Reqwest! A general purpose XHR connection manager
  * license MIT (c) Dustin Diaz 2015
  * https://github.com/ded/reqwest
  */

!function (name, context, definition) {
  if (typeof module != 'undefined' && module.exports) module.exports = definition()
  else if (typeof define == 'function' && define.amd) define(definition)
  else context[name] = definition()
}('reqwest', this, function () {

  var context = this

  if ('window' in context) {
    var doc = document
      , byTag = 'getElementsByTagName'
      , head = doc[byTag]('head')[0]
  } else {
    var XHR2
    try {
      XHR2 = require('xhr2')
    } catch (ex) {
      throw new Error('Peer dependency `xhr2` required! Please npm install xhr2')
    }
  }


  var httpsRe = /^http/
    , protocolRe = /(^\w+):\/\//
    , twoHundo = /^(20\d|1223)$/ //http://stackoverflow.com/questions/10046972/msie-returns-status-code-of-1223-for-ajax-request
    , readyState = 'readyState'
    , contentType = 'Content-Type'
    , requestedWith = 'X-Requested-With'
    , uniqid = 0
    , callbackPrefix = 'reqwest_' + (+new Date())
    , lastValue // data stored by the most recent JSONP callback
    , xmlHttpRequest = 'XMLHttpRequest'
    , xDomainRequest = 'XDomainRequest'
    , noop = function () {}

    , isArray = typeof Array.isArray == 'function'
        ? Array.isArray
        : function (a) {
            return a instanceof Array
          }

    , defaultHeaders = {
          'contentType': 'application/x-www-form-urlencoded'
        , 'requestedWith': xmlHttpRequest
        , 'accept': {
              '*':  'text/javascript, text/html, application/xml, text/xml, */*'
            , 'xml':  'application/xml, text/xml'
            , 'html': 'text/html'
            , 'text': 'text/plain'
            , 'json': 'application/json, text/javascript'
            , 'js':   'application/javascript, text/javascript'
          }
      }

    , xhr = function(o) {
        // is it x-domain
        if (o['crossOrigin'] === true) {
          var xhr = context[xmlHttpRequest] ? new XMLHttpRequest() : null
          if (xhr && 'withCredentials' in xhr) {
            return xhr
          } else if (context[xDomainRequest]) {
            return new XDomainRequest()
          } else {
            throw new Error('Browser does not support cross-origin requests')
          }
        } else if (context[xmlHttpRequest]) {
          return new XMLHttpRequest()
        } else if (XHR2) {
          return new XHR2()
        } else {
          return new ActiveXObject('Microsoft.XMLHTTP')
        }
      }
    , globalSetupOptions = {
        dataFilter: function (data) {
          return data
        }
      }

  function succeed(r) {
    var protocol = protocolRe.exec(r.url)
    protocol = (protocol && protocol[1]) || context.location.protocol
    return httpsRe.test(protocol) ? twoHundo.test(r.request.status) : !!r.request.response
  }

  function handleReadyState(r, success, error) {
    return function () {
      // use _aborted to mitigate against IE err c00c023f
      // (can't read props on aborted request objects)
      if (r._aborted) return error(r.request)
      if (r._timedOut) return error(r.request, 'Request is aborted: timeout')
      if (r.request && r.request[readyState] == 4) {
        r.request.onreadystatechange = noop
        if (succeed(r)) success(r.request)
        else
          error(r.request)
      }
    }
  }

  function setHeaders(http, o) {
    var headers = o['headers'] || {}
      , h

    headers['Accept'] = headers['Accept']
      || defaultHeaders['accept'][o['type']]
      || defaultHeaders['accept']['*']

    var isAFormData = typeof FormData !== 'undefined' && (o['data'] instanceof FormData);
    // breaks cross-origin requests with legacy browsers
    if (!o['crossOrigin'] && !headers[requestedWith]) headers[requestedWith] = defaultHeaders['requestedWith']
    if (!headers[contentType] && !isAFormData) headers[contentType] = o['contentType'] || defaultHeaders['contentType']
    for (h in headers)
      headers.hasOwnProperty(h) && 'setRequestHeader' in http && http.setRequestHeader(h, headers[h])
  }

  function setCredentials(http, o) {
    if (typeof o['withCredentials'] !== 'undefined' && typeof http.withCredentials !== 'undefined') {
      http.withCredentials = !!o['withCredentials']
    }
  }

  function generalCallback(data) {
    lastValue = data
  }

  function urlappend (url, s) {
    return url + (/\?/.test(url) ? '&' : '?') + s
  }

  function handleJsonp(o, fn, err, url) {
    var reqId = uniqid++
      , cbkey = o['jsonpCallback'] || 'callback' // the 'callback' key
      , cbval = o['jsonpCallbackName'] || reqwest.getcallbackPrefix(reqId)
      , cbreg = new RegExp('((^|\\?|&)' + cbkey + ')=([^&]+)')
      , match = url.match(cbreg)
      , script = doc.createElement('script')
      , loaded = 0
      , isIE10 = navigator.userAgent.indexOf('MSIE 10.0') !== -1

    if (match) {
      if (match[3] === '?') {
        url = url.replace(cbreg, '$1=' + cbval) // wildcard callback func name
      } else {
        cbval = match[3] // provided callback func name
      }
    } else {
      url = urlappend(url, cbkey + '=' + cbval) // no callback details, add 'em
    }

    context[cbval] = generalCallback

    script.type = 'text/javascript'
    script.src = url
    script.async = true
    if (typeof script.onreadystatechange !== 'undefined' && !isIE10) {
      // need this for IE due to out-of-order onreadystatechange(), binding script
      // execution to an event listener gives us control over when the script
      // is executed. See http://jaubourg.net/2010/07/loading-script-as-onclick-handler-of.html
      script.htmlFor = script.id = '_reqwest_' + reqId
    }

    script.onload = script.onreadystatechange = function () {
      if ((script[readyState] && script[readyState] !== 'complete' && script[readyState] !== 'loaded') || loaded) {
        return false
      }
      script.onload = script.onreadystatechange = null
      script.onclick && script.onclick()
      // Call the user callback with the last value stored and clean up values and scripts.
      fn(lastValue)
      lastValue = undefined
      head.removeChild(script)
      loaded = 1
    }

    // Add the script to the DOM head
    head.appendChild(script)

    // Enable JSONP timeout
    return {
      abort: function () {
        script.onload = script.onreadystatechange = null
        err({}, 'Request is aborted: timeout', {})
        lastValue = undefined
        head.removeChild(script)
        loaded = 1
      }
    }
  }

  function getRequest(fn, err) {
    var o = this.o
      , method = (o['method'] || 'GET').toUpperCase()
      , url = typeof o === 'string' ? o : o['url']
      // convert non-string objects to query-string form unless o['processData'] is false
      , data = (o['processData'] !== false && o['data'] && typeof o['data'] !== 'string')
        ? reqwest.toQueryString(o['data'])
        : (o['data'] || null)
      , http
      , sendWait = false

    // if we're working on a GET request and we have data then we should append
    // query string to end of URL and not post data
    if ((o['type'] == 'jsonp' || method == 'GET') && data) {
      url = urlappend(url, data)
      data = null
    }

    if (o['type'] == 'jsonp') return handleJsonp(o, fn, err, url)

    // get the xhr from the factory if passed
    // if the factory returns null, fall-back to ours
    http = (o.xhr && o.xhr(o)) || xhr(o)

    http.open(method, url, o['async'] === false ? false : true)
    setHeaders(http, o)
    setCredentials(http, o)
    if (context[xDomainRequest] && http instanceof context[xDomainRequest]) {
        http.onload = fn
        http.onerror = err
        // NOTE: see
        // http://social.msdn.microsoft.com/Forums/en-US/iewebdevelopment/thread/30ef3add-767c-4436-b8a9-f1ca19b4812e
        http.onprogress = function() {}
        sendWait = true
    } else {
      http.onreadystatechange = handleReadyState(this, fn, err)
    }
    o['before'] && o['before'](http)
    if (sendWait) {
      setTimeout(function () {
        http.send(data)
      }, 200)
    } else {
      http.send(data)
    }
    return http
  }

  function Reqwest(o, fn) {
    this.o = o
    this.fn = fn

    init.apply(this, arguments)
  }

  function setType(header) {
    // json, javascript, text/plain, text/html, xml
    if (header === null) return undefined; //In case of no content-type.
    if (header.match('json')) return 'json'
    if (header.match('javascript')) return 'js'
    if (header.match('text')) return 'html'
    if (header.match('xml')) return 'xml'
  }

  function init(o, fn) {

    this.url = typeof o == 'string' ? o : o['url']
    this.timeout = null

    // whether request has been fulfilled for purpose
    // of tracking the Promises
    this._fulfilled = false
    // success handlers
    this._successHandler = function(){}
    this._fulfillmentHandlers = []
    // error handlers
    this._errorHandlers = []
    // complete (both success and fail) handlers
    this._completeHandlers = []
    this._erred = false
    this._responseArgs = {}

    var self = this

    fn = fn || function () {}

    if (o['timeout']) {
      this.timeout = setTimeout(function () {
        timedOut()
      }, o['timeout'])
    }

    if (o['success']) {
      this._successHandler = function () {
        o['success'].apply(o, arguments)
      }
    }

    if (o['error']) {
      this._errorHandlers.push(function () {
        o['error'].apply(o, arguments)
      })
    }

    if (o['complete']) {
      this._completeHandlers.push(function () {
        o['complete'].apply(o, arguments)
      })
    }

    function complete (resp) {
      o['timeout'] && clearTimeout(self.timeout)
      self.timeout = null
      while (self._completeHandlers.length > 0) {
        self._completeHandlers.shift()(resp)
      }
    }

    function success (resp) {
      var type = o['type'] || resp && setType(resp.getResponseHeader('Content-Type')) // resp can be undefined in IE
      resp = (type !== 'jsonp') ? self.request : resp
      // use global data filter on response text
      var filteredResponse = globalSetupOptions.dataFilter(resp.responseText, type)
        , r = filteredResponse
      try {
        resp.responseText = r
      } catch (e) {
        // can't assign this in IE<=8, just ignore
      }
      if (r) {
        switch (type) {
        case 'json':
          try {
            resp = context.JSON ? context.JSON.parse(r) : eval('(' + r + ')')
          } catch (err) {
            return error(resp, 'Could not parse JSON in response', err)
          }
          break
        case 'js':
          resp = eval(r)
          break
        case 'html':
          resp = r
          break
        case 'xml':
          resp = resp.responseXML
              && resp.responseXML.parseError // IE trololo
              && resp.responseXML.parseError.errorCode
              && resp.responseXML.parseError.reason
            ? null
            : resp.responseXML
          break
        }
      }

      self._responseArgs.resp = resp
      self._fulfilled = true
      fn(resp)
      self._successHandler(resp)
      while (self._fulfillmentHandlers.length > 0) {
        resp = self._fulfillmentHandlers.shift()(resp)
      }

      complete(resp)
    }

    function timedOut() {
      self._timedOut = true
      self.request.abort()
    }

    function error(resp, msg, t) {
      resp = self.request
      self._responseArgs.resp = resp
      self._responseArgs.msg = msg
      self._responseArgs.t = t
      self._erred = true
      while (self._errorHandlers.length > 0) {
        self._errorHandlers.shift()(resp, msg, t)
      }
      complete(resp)
    }

    this.request = getRequest.call(this, success, error)
  }

  Reqwest.prototype = {
    abort: function () {
      this._aborted = true
      this.request.abort()
    }

  , retry: function () {
      init.call(this, this.o, this.fn)
    }

    /**
     * Small deviation from the Promises A CommonJs specification
     * http://wiki.commonjs.org/wiki/Promises/A
     */

    /**
     * `then` will execute upon successful requests
     */
  , then: function (success, fail) {
      success = success || function () {}
      fail = fail || function () {}
      if (this._fulfilled) {
        this._responseArgs.resp = success(this._responseArgs.resp)
      } else if (this._erred) {
        fail(this._responseArgs.resp, this._responseArgs.msg, this._responseArgs.t)
      } else {
        this._fulfillmentHandlers.push(success)
        this._errorHandlers.push(fail)
      }
      return this
    }

    /**
     * `always` will execute whether the request succeeds or fails
     */
  , always: function (fn) {
      if (this._fulfilled || this._erred) {
        fn(this._responseArgs.resp)
      } else {
        this._completeHandlers.push(fn)
      }
      return this
    }

    /**
     * `fail` will execute when the request fails
     */
  , fail: function (fn) {
      if (this._erred) {
        fn(this._responseArgs.resp, this._responseArgs.msg, this._responseArgs.t)
      } else {
        this._errorHandlers.push(fn)
      }
      return this
    }
  , 'catch': function (fn) {
      return this.fail(fn)
    }
  }

  function reqwest(o, fn) {
    return new Reqwest(o, fn)
  }

  // normalize newline variants according to spec -> CRLF
  function normalize(s) {
    return s ? s.replace(/\r?\n/g, '\r\n') : ''
  }

  function serial(el, cb) {
    var n = el.name
      , t = el.tagName.toLowerCase()
      , optCb = function (o) {
          // IE gives value="" even where there is no value attribute
          // 'specified' ref: http://www.w3.org/TR/DOM-Level-3-Core/core.html#ID-862529273
          if (o && !o['disabled'])
            cb(n, normalize(o['attributes']['value'] && o['attributes']['value']['specified'] ? o['value'] : o['text']))
        }
      , ch, ra, val, i

    // don't serialize elements that are disabled or without a name
    if (el.disabled || !n) return

    switch (t) {
    case 'input':
      if (!/reset|button|image|file/i.test(el.type)) {
        ch = /checkbox/i.test(el.type)
        ra = /radio/i.test(el.type)
        val = el.value
        // WebKit gives us "" instead of "on" if a checkbox has no value, so correct it here
        ;(!(ch || ra) || el.checked) && cb(n, normalize(ch && val === '' ? 'on' : val))
      }
      break
    case 'textarea':
      cb(n, normalize(el.value))
      break
    case 'select':
      if (el.type.toLowerCase() === 'select-one') {
        optCb(el.selectedIndex >= 0 ? el.options[el.selectedIndex] : null)
      } else {
        for (i = 0; el.length && i < el.length; i++) {
          el.options[i].selected && optCb(el.options[i])
        }
      }
      break
    }
  }

  // collect up all form elements found from the passed argument elements all
  // the way down to child elements; pass a '<form>' or form fields.
  // called with 'this'=callback to use for serial() on each element
  function eachFormElement() {
    var cb = this
      , e, i
      , serializeSubtags = function (e, tags) {
          var i, j, fa
          for (i = 0; i < tags.length; i++) {
            fa = e[byTag](tags[i])
            for (j = 0; j < fa.length; j++) serial(fa[j], cb)
          }
        }

    for (i = 0; i < arguments.length; i++) {
      e = arguments[i]
      if (/input|select|textarea/i.test(e.tagName)) serial(e, cb)
      serializeSubtags(e, [ 'input', 'select', 'textarea' ])
    }
  }

  // standard query string style serialization
  function serializeQueryString() {
    return reqwest.toQueryString(reqwest.serializeArray.apply(null, arguments))
  }

  // { 'name': 'value', ... } style serialization
  function serializeHash() {
    var hash = {}
    eachFormElement.apply(function (name, value) {
      if (name in hash) {
        hash[name] && !isArray(hash[name]) && (hash[name] = [hash[name]])
        hash[name].push(value)
      } else hash[name] = value
    }, arguments)
    return hash
  }

  // [ { name: 'name', value: 'value' }, ... ] style serialization
  reqwest.serializeArray = function () {
    var arr = []
    eachFormElement.apply(function (name, value) {
      arr.push({name: name, value: value})
    }, arguments)
    return arr
  }

  reqwest.serialize = function () {
    if (arguments.length === 0) return ''
    var opt, fn
      , args = Array.prototype.slice.call(arguments, 0)

    opt = args.pop()
    opt && opt.nodeType && args.push(opt) && (opt = null)
    opt && (opt = opt.type)

    if (opt == 'map') fn = serializeHash
    else if (opt == 'array') fn = reqwest.serializeArray
    else fn = serializeQueryString

    return fn.apply(null, args)
  }

  reqwest.toQueryString = function (o, trad) {
    var prefix, i
      , traditional = trad || false
      , s = []
      , enc = encodeURIComponent
      , add = function (key, value) {
          // If value is a function, invoke it and return its value
          value = ('function' === typeof value) ? value() : (value == null ? '' : value)
          s[s.length] = enc(key) + '=' + enc(value)
        }
    // If an array was passed in, assume that it is an array of form elements.
    if (isArray(o)) {
      for (i = 0; o && i < o.length; i++) add(o[i]['name'], o[i]['value'])
    } else {
      // If traditional, encode the "old" way (the way 1.3.2 or older
      // did it), otherwise encode params recursively.
      for (prefix in o) {
        if (o.hasOwnProperty(prefix)) buildParams(prefix, o[prefix], traditional, add)
      }
    }

    // spaces should be + according to spec
    return s.join('&').replace(/%20/g, '+')
  }

  function buildParams(prefix, obj, traditional, add) {
    var name, i, v
      , rbracket = /\[\]$/

    if (isArray(obj)) {
      // Serialize array item.
      for (i = 0; obj && i < obj.length; i++) {
        v = obj[i]
        if (traditional || rbracket.test(prefix)) {
          // Treat each array item as a scalar.
          add(prefix, v)
        } else {
          buildParams(prefix + '[' + (typeof v === 'object' ? i : '') + ']', v, traditional, add)
        }
      }
    } else if (obj && obj.toString() === '[object Object]') {
      // Serialize object item.
      for (name in obj) {
        buildParams(prefix + '[' + name + ']', obj[name], traditional, add)
      }

    } else {
      // Serialize scalar item.
      add(prefix, obj)
    }
  }

  reqwest.getcallbackPrefix = function () {
    return callbackPrefix
  }

  // jQuery and Zepto compatibility, differences can be remapped here so you can call
  // .ajax.compat(options, callback)
  reqwest.compat = function (o, fn) {
    if (o) {
      o['type'] && (o['method'] = o['type']) && delete o['type']
      o['dataType'] && (o['type'] = o['dataType'])
      o['jsonpCallback'] && (o['jsonpCallbackName'] = o['jsonpCallback']) && delete o['jsonpCallback']
      o['jsonp'] && (o['jsonpCallback'] = o['jsonp'])
    }
    return new Reqwest(o, fn)
  }

  reqwest.ajaxSetup = function (options) {
    options = options || {}
    for (var k in options) {
      globalSetupOptions[k] = options[k]
    }
  }

  return reqwest
});

},{"xhr2":1}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * 定数定義
 */
var Constants = {
  apiHost: '__CONSTANT_API_HOST__',
  buildTime: '__BUILD_TIME__'
};

exports.default = Constants;

},{}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _constants = require('./constants.es6');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * エラー収集
 *
 * サーバにエラーを通知
 */
var ErrorReporter = {
  report: function report(error) {
    var line = encodeURIComponent(error.line || error.lineNumber || 0);
    var url = encodeURIComponent(error.sourceURL || error.fileName || location.href || '');
    var name = encodeURIComponent(error.name || '');
    var stack = encodeURIComponent(error.stack || '');
    var documentMode = encodeURIComponent(document.documentMode || '');
    var buildTime = encodeURIComponent(_constants2.default.buildTime);
    new Image().src = _constants2.default.apiHost + '/collect_error.gif?name=' + name + '&line=' + line + '&url=' + url + '&stack=' + stack + '&documentMode=' + documentMode + '&buildTime=' + buildTime;
    return true;
  }
};

exports.default = ErrorReporter;

},{"./constants.es6":3}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * SDK設定項目リスト
 */
var defaultConfigValue = {
  redirectAfterSubmission: true,
  afterSubmission: undefined
};

/**
 * SDKの設定
 *
 * SDK.configure('oYL4jFkYQnBE5DS7OUO8', {
 *   // 送信後リダイレクトするか
 *   redirectAfterSubmission: false,
 *
 *   // 送信後に実行するメソッド
 *   afterSubmission: function (key, data) { ... }
 * });
 */
var FormConfig =
/**
 * @param {String} key - フォームキー
 */
function FormConfig(_formKey, _config) {
  _classCallCheck(this, FormConfig);

  this._formKey = _formKey;
  var self = this;

  Object.keys(defaultConfigValue).forEach(function (configKey) {
    if (_config[configKey] == undefined) {
      self[configKey] = defaultConfigValue[configKey];
    } else {
      self[configKey] = _config[configKey];
    }
  });
};

exports.default = FormConfig;

},{}],6:[function(require,module,exports){
'use strict';

var _sdk = require('./sdk.es6');

var _sdk2 = _interopRequireDefault(_sdk);

var _utils = require('./utils.es6');

var _utils2 = _interopRequireDefault(_utils);

var _error_reporter = require('./error_reporter.es6');

var _error_reporter2 = _interopRequireDefault(_error_reporter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(function () {
  try {
    /**
     * ブラウザバージョンチェック
     *
     * IE9以下(互換モード含む)では起動しないように
     * see: https://github.com/tadyjp/formrun/issues/478
     */
    if (_utils2.default.lteIE9) {
      _utils2.default.warn('Formrun.SDK does not support this browser.');
      return false;
    }

    /**
     * プロトコルチェック
     *
     * `file:` からは実行できない
     */
    if (window.location.protocol === 'file:') {
      _utils2.default.warn('Formrun.SDK does not support "file:" protocol.');
      return false;
    }

    /**
     * グローバル化
     */
    var Formrun = window.Formrun = window.Formrun || {};

    /**
     * 二重定義のチェック
     *
     * 既に `Formrun.SDK` が定義されている場合は、再定義しない
     */
    if (Formrun.SDK !== undefined) {
      _utils2.default.warn('Formrun.SDK is already defined.');
      return false;
    }

    Formrun.SDK = _sdk2.default;

    window.addEventListener('DOMContentLoaded', _sdk2.default.init, false);
  } catch (error) {
    _error_reporter2.default.report(error);
    throw error;
  }
})();

},{"./error_reporter.es6":4,"./sdk.es6":7,"./utils.es6":8}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

var _reqwest = require('reqwest');

var _reqwest2 = _interopRequireDefault(_reqwest);

var _form_config = require('./form_config.es6');

var _form_config2 = _interopRequireDefault(_form_config);

var _constants = require('./constants.es6');

var _constants2 = _interopRequireDefault(_constants);

var _error_reporter = require('./error_reporter.es6');

var _error_reporter2 = _interopRequireDefault(_error_reporter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Namespace
 */
var SDK = {
  // 設定値を保存する場所
  _config: {},

  // Formrun SDKが有効か
  _enabled: true,

  // ajax送信中か
  _ajaxState: false
};

/**
 * フォーム要素のクラス
 */
var FORM_CLASS_NAME = ".formrun";

/**
 * フォームキーの属性名
 */
var FORM_KEY_RE = /\/v(\d+)\/r\/([^\/]+)$/;

/**
 * SDK設定
 */
SDK.configure = function (_formKey, _config) {
  SDK._config[_formKey] = new _form_config2.default(_formKey, _config);
};

/**
 * 初期化
 */
SDK.init = function () {
  try {
    SDK.Form.initializeFromClassName();
    return true;
  } catch (error) {
    _error_reporter2.default.report(error);
    throw error;
  }
};

SDK.Form = (function () {
  /**
   * @param {Form DOM} form - フォームElement
   */

  function Form(form) {
    _classCallCheck(this, Form);

    this._form = form;
  }

  /**
   * フォームのパラメータをJSONで取得
   *
   * @return {Array} - フォームパラメータの配列
   */

  _createClass(Form, [{
    key: 'getFormParamsJSON',
    value: function getFormParamsJSON() {
      return JSON.stringify(this.getFormParams());
    }

    /**
     * フォームのパラメータを取得
     *
     * @return {Array} - フォームパラメータの配列
     * see: https://github.com/jquery/jquery/blob/70605c8e5655da996ebd395e3c43423daaa08d9c/src/serialize.js#L106
     */

  }, {
    key: 'getFormParams',
    value: function getFormParams() {
      var dataHash = {};

      Array.prototype.forEach.call(this._form.elements, function (el) {

        if (el.type == "radio" && !el.checked) {
          // チェックされていないものを除く
          return;
        }
        if (el.type == "checkbox" && !el.checked) {
          // チェックされていないものを除く
          return;
        }
        if (el.type == "submit") {
          // submitタグを除く
          return;
        }
        if (!el.name || el.name == "" || !el.value || el.value == "") {
          // 無効な値をskip
          return;
        }

        var name = el.name.replace(/\[\]/g, '');
        var value = el.value;

        // 同じnameのfield
        if (dataHash[name]) {
          dataHash[name] += ', ' + value;
        } else {
          dataHash[name] = value;
        }
      });

      var dataList = [];
      for (var name in dataHash) {
        dataList.push({
          name: name,
          value: dataHash[name]
        });
      }

      return dataList;
    }

    /**
     * XHRリクエスト送信
     *
     * @return {reqwest Promise} - Promiseオブジェクト
     */

  }, {
    key: 'sendData',
    value: function sendData() {
      SDK._ajaxState = true;

      var JSONPayload = this.getFormParamsJSON();
      var apiKey = this.getApiKey();
      var apiHost = _constants2.default.apiHost;

      return (0, _reqwest2.default)({
        url: apiHost + '/api/v1/f/' + apiKey,
        method: "post",
        contentType: "application/json",
        crossOrigin: true,
        data: JSONPayload
      }).always(function () {
        SDK._ajaxState = false;
      });
    }

    /**
     * @return {string} - タグバージョン
     */

  }, {
    key: 'getTagVersion',
    value: function getTagVersion() {
      var match = FORM_KEY_RE.exec(this._form.getAttribute('action'));
      return match[1];
    }

    /**
     * @return {string} - フォームキー
     */

  }, {
    key: 'getApiKey',
    value: function getApiKey() {
      var match = FORM_KEY_RE.exec(this._form.getAttribute('action'));
      return match[2];
    }

    /**
     * 送信時のフックを設定
     */

  }, {
    key: 'setSubmittionHook',
    value: function setSubmittionHook() {
      var _this = this;

      this._form.addEventListener('submit', function (e) {
        try {
          (function () {
            e.preventDefault();

            var formConfig = SDK._config[_this.getApiKey()];

            _this.sendData().then(function (res) {
              if (formConfig == undefined) {
                if (res.location) {
                  // リダイレクト指定があればURL変更
                  location.href = res.location;
                }
              } else {

                if (formConfig.afterSubmission != undefined) {
                  // `afterSubmission`が設定されていれば実行
                  formConfig.afterSubmission(_this.getApiKey(), _this.getFormParams());
                }

                if (res.location && formConfig.redirectAfterSubmission) {
                  // リダイレクト指定があればURL変更
                  location.href = res.location;
                }
              }
            });
          })();
        } catch (error) {
          _error_reporter2.default.report(error);
          throw error;
        }
      });
    }
  }], [{
    key: 'initializeFromClassName',
    value: function initializeFromClassName() {
      var forms = document.querySelectorAll(FORM_CLASS_NAME);
      var form_instances = [];
      Array.prototype.forEach.call(forms, function (form) {
        var form_instance = new Form(form);
        if (form_instance.getTagVersion() == '1') {
          form_instance.setSubmittionHook();
          form_instances.push(form_instance);
        }
      });
      return form_instances;
    }
  }]);

  return Form;
})();

exports.default = SDK;

},{"./constants.es6":3,"./error_reporter.es6":4,"./form_config.es6":5,"reqwest":2}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Utilities
 */
var Utils = {
  // @see: http://dtp.jdash.info/archives/Detect_IE_version_using_JavaScript
  // true if <= IE9
  lteIE9: !!(document.uniqueID && typeof window.matchMedia === 'undefined')

  // true if IE8
  , eqIE8: !!(document.uniqueID && document.documentMode === 8)

  // true if IE9
  , eqIE9: !!(document.uniqueID && document.documentMode === 9)

  // true if IE9
  , warn: function warn(msg) {
    if (!window.console) {
      return false;
    }

    if (console.warn) {
      console.warn('[formrun debug] ' + msg);
    } else if (console.log) {
      console.log('[formrun debug] ' + msg);
    }
  }
};

exports.default = Utils;

},{}]},{},[6]);
